function initCarousel(){
    $('#carousel, #object .object-carousel').slick({
        dots: true,
        lazyLoad: 'ondemand',
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000
    });

    $('.verkopen-carousel').slick({
        dots: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        responsive: [
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.sponsors.carousel').slick({
        dots: true,
        lazyLoad: 'ondemand',
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
};

function initNavigation(){
    $('.toggleNav').click(function () {
        $('#navigation').toggleClass('show');
    });
};

function initContact() {
    if ($('#contact').length > 0) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}


$(document).ready(function () {
    initCarousel();
    initNavigation();
    initContact();
});